import React from "react";
import s from "./TechStack.module.scss";
import aws from "./images/aws.svg";
import kubernetes from "./images/kubernetes.svg";
import java from "./images/java.svg";
import spring from "./images/spring.svg";
import html from "./images/html.svg";
import css from "./images/css.svg";
import js from "./images/js.svg";
import reactLogo from "./images/react.svg";

const TechStack = () => {
  return (
    <div className={s.techStack}>
      <h3>Tech stack</h3>
      <div className={s.list}>
        <div className={s.tech}>
          <img src={aws} alt={aws} />
          <p className={s.name}>AWS</p>
        </div>
        <div className={s.tech}>
          <img src={kubernetes} alt={kubernetes} />
          <p className={s.name}>Kubernetes</p>
        </div>
        <div className={s.tech}>
          <img src={java} alt={java} />
          <p className={s.name}>Java</p>
        </div>
        <div className={s.tech}>
          <img src={spring} alt={spring} />
          <p className={s.name}>Spring</p>
        </div>
        <div className={s.tech}>
          <img src={html} alt={html} />
          <p className={s.name}>HTML</p>
        </div>
        <div className={s.tech}>
          <img src={css} alt={css} />
          <p className={s.name}>CSS</p>
        </div>
        <div className={s.tech}>
          <img src={js} alt={js} />
          <p className={s.name}>Javascript</p>
        </div>
        <div className={s.tech}>
          <img src={reactLogo} alt={reactLogo} />
          <p className={s.name}>React</p>
        </div>
      </div>
    </div>
  );
};

export default TechStack;
