import React from "react";
import cn from "classnames";
import s from "./LegacySoftwareRedesign.module.scss";
import { TechStack, WorkWithUs } from "common/components";
import { Container } from "common/ui-kit";
import ifYouAre1 from "./images/svg/if-you-are-1.svg";
import ifYouAre2 from "./images/svg/if-you-are-2.svg";
import ifYouAre3 from "./images/svg/if-you-are-3.svg";

const LegacySoftwareRedesign = ({ className }) => {
  return (
    <main className={cn(s.legacySoftware, className)}>
      <Container className={s.main}>
        <Container full={false} className={s.mainInner}>
          <h1>
            We are here
            <br />
            <strong>
              to accelarate your
              <br />
              business
            </strong>
          </h1>
        </Container>
      </Container>
      <Container className={s.ifYouAre}>
        <Container full={false} className={cn(s.inner, s.ifYouAreInner)}>
          <h3>If you are...</h3>
          <div className={s.items}>
            <div className={s.item}>
              <div className={s.image}>
                <img src={ifYouAre1} alt={ifYouAre1} />
              </div>
              <h4 className={s.title}>
                ...tired of slow software releases. One minor change requires
                weeks of work and even more time to test
              </h4>
            </div>
            <div className={s.item}>
              <div className={s.image}>
                <img src={ifYouAre2} alt={ifYouAre2} />
              </div>
              <h4 className={s.title}>
                ...in mind that team size not manageable anymore, but you still
                need more people on board
              </h4>
            </div>
            <div className={s.item}>
              <div className={s.image}>
                <img src={ifYouAre3} alt={ifYouAre3} />
              </div>
              <h4 className={s.title}>...onboarding takes months</h4>
            </div>
          </div>
        </Container>
      </Container>
      <Container className={s.softwareRedesign}>
        <Container
          full={false}
          className={cn(s.inner, s.softwareRedesignInner)}
        >
          <h3>The answer is software redesign</h3>
          <div className={s.items}>
            <div className={s.item}>
              <div className={s.number}>1</div>
              <p data-small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse arcu mauris, imperdiet a blandit nec, tristique
                vitae erat.
              </p>
            </div>
            <div className={s.item}>
              <div className={s.number}>2</div>
              <p data-small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse arcu mauris, imperdiet a blandit nec, tristique
                vitae erat.
              </p>
            </div>
            <div className={s.item}>
              <div className={s.number}>3</div>
              <p data-small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse arcu mauris, imperdiet a blandit nec, tristique
                vitae erat.
              </p>
            </div>
            <div className={s.item}>
              <div className={s.number}>4</div>
              <p data-small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse arcu mauris, imperdiet a blandit nec, tristique
                vitae erat.
              </p>
            </div>
            <div className={s.item}>
              <div className={s.number}>5</div>
              <p data-small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse arcu mauris, imperdiet a blandit nec, tristique
                vitae erat.
              </p>
            </div>
            <div className={s.item}>
              <div className={s.number}>6</div>
              <p data-small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse arcu mauris, imperdiet a blandit nec, tristique
                vitae erat.
              </p>
            </div>
          </div>
        </Container>
      </Container>

      <Container className={s.impact}>
        <Container full={false} className={cn(s.inner, s.impactInner)}>
          <h3>Impact</h3>
          <div className={s.charts}>
            <div className={s.chartWrap}>
              <div className={s.chart}>
                <div className={cn(s.axis, s.axisY)}>
                  <div className={s.units}>
                    <div>100%</div>
                    <div>80%</div>
                    <div>60%</div>
                    <div>40%</div>
                    <div>20%</div>
                  </div>
                </div>
                <div className={s.data}>
                  <div className={cn(s.arrow, s.arrowTop)} />
                  <div className={cn(s.arrow, s.arrowLeft)} />
                  <div className={s.candle} style={{ height: "100%" }} />
                  <div className={s.candle} style={{ height: "80%" }} />
                  <div className={s.candle} style={{ height: "60%" }} />
                  <div className={s.candle} style={{ height: "80%" }} />
                  <div className={s.candle} style={{ height: "100%" }} />
                </div>
                <div className={s.title}>Steps</div>
                <div className={cn(s.axis, s.axisX)}>
                  <div className={s.units}>
                    <div>1</div>
                    <div>2</div>
                    <div>3</div>
                    <div>4</div>
                    <div>5</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.chartWrap}>
              <div className={s.chart}>
                <div className={cn(s.axis, s.axisY)}>
                  <div className={s.units}>
                    <div>100%</div>
                    <div>80%</div>
                    <div>60%</div>
                    <div>40%</div>
                    <div>20%</div>
                  </div>
                </div>
                <div className={s.data}>
                  <div className={cn(s.arrow, s.arrowTop)} />
                  <div className={cn(s.arrow, s.arrowLeft)} />
                  <div className={s.candle} style={{ height: "45%" }} />
                  <div className={s.candle} style={{ height: "77%" }} />
                  <div className={s.candle} style={{ height: "23%" }} />
                  <div className={s.candle} style={{ height: "19%" }} />
                  <div className={s.candle} style={{ height: "36%" }} />
                </div>
                <div className={s.title}>Steps</div>
                <div className={cn(s.axis, s.axisX)}>
                  <div className={s.units}>
                    <div>1</div>
                    <div>2</div>
                    <div>3</div>
                    <div>4</div>
                    <div>5</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.chartWrap}>
              <div className={s.chart}>
                <div className={cn(s.axis, s.axisY)}>
                  <div className={s.units}>
                    <div>100%</div>
                    <div>80%</div>
                    <div>60%</div>
                    <div>40%</div>
                    <div>20%</div>
                  </div>
                </div>
                <div className={s.data}>
                  <div className={cn(s.arrow, s.arrowTop)} />
                  <div className={cn(s.arrow, s.arrowLeft)} />
                  <div className={s.candle} style={{ height: "90%" }} />
                  <div className={s.candle} style={{ height: "70%" }} />
                  <div className={s.candle} style={{ height: "30%" }} />
                  <div className={s.candle} style={{ height: "60%" }} />
                  <div className={s.candle} style={{ height: "10%" }} />
                </div>
                <div className={s.title}>Steps</div>
                <div className={cn(s.axis, s.axisX)}>
                  <div className={s.units}>
                    <div>1</div>
                    <div>2</div>
                    <div>3</div>
                    <div>4</div>
                    <div>5</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container>

      <Container>
        <Container full={false} className={s.inner}>
          <TechStack />
        </Container>
      </Container>

      <Container>
        <Container full={false} className={s.inner}>
          <WorkWithUs />
        </Container>
      </Container>
    </main>
  );
};

export default LegacySoftwareRedesign;
