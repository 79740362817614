// import React from 'react';
import { toast } from 'react-toastify';

toast.configure({
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const Notify = ({ message, config }) => toast(message, config);

export default Notify;
