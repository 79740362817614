import React, { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink } from "common/ui-kit";
import s from "./Header.module.scss";

// icons
import menu from "./images/menu.svg";
import close from "./images/close.svg";

const Header = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const onChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <header className={s.header}>
      <NavLink to="/" className={s.logo}>
        Logo
      </NavLink>
      <nav className={s.menu}>
        <NavLink to="/" className={s.item}>
          {t("home")}
        </NavLink>
        <NavLink to="/legacy-software-redesign" className={s.item}>
          Legacy Software
        </NavLink>
        <NavLink to="/outstaffing" className={s.item}>
          Outstaffing
        </NavLink>
        <NavLink to="/software-development" className={s.item}>
          Software Development
        </NavLink>
        <select
          className={cn(s.item, s.lang)}
          name="lang"
          id="lang"
          onChange={(e) => onChangeLang(e.target.value)}
          value={language}
        >
          <option value="en">EN</option>
          <option value="ua">UA</option>
          {/* <option disabled value="uk">
            UK (soon)
          </option> */}
        </select>
      </nav>
      <div className={cn(s.burger, menuOpen && s.open)} id="burger">
        <div className={cn(s.image, menuOpen && s.open)} onClick={toggleMenu}>
          <img src={menu} alt="" />
        </div>
        <div className={cn(s.image, !menuOpen && s.close)} onClick={toggleMenu}>
          <img src={close} alt="" />
        </div>
        <nav className={s.mobileMenu}>
          <NavLink to="/" className={s.item}>
            {t("home")}
          </NavLink>
          <NavLink to="/legacy-software-redesign" className={s.item}>
            Legacy Software
          </NavLink>
          <NavLink to="/outstaffing" className={s.item}>
            Outstaffing
          </NavLink>
          <NavLink to="/software-development" className={s.item}>
            Software Development
          </NavLink>
          <div className={cn(s.item, s.lang)}>
            Language:
            <select
              className={s.lang}
              name="lang"
              id="lang"
              onChange={(e) => onChangeLang(e.target.value)}
              value={language}
            >
              <option value="en">EN</option>
              <option value="ua">UA</option>
              {/* <option disabled value="uk">
                UK (soon)
              </option> */}
            </select>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
