import { Route, Switch } from "react-router-dom";
import { Header, Footer } from "common/components";
import Home from "./home";
import LegacySoftwareRedesign from "./legacy-software-redesign";
import Outstaffing from "./outstaffing";
import SoftwareDevelopment from "./software-development";

const App = () => {
  return (
    <div className="app">
      <Header />
      <Switch>
        <Route exact path="/" render={(props) => <Home {...props} />} />
        <Route
          path="/legacy-software-redesign"
          render={(props) => <LegacySoftwareRedesign {...props} />}
        />
        <Route
          path="/outstaffing"
          render={(props) => <Outstaffing {...props} />}
        />
        <Route
          path="/software-development"
          render={(props) => <SoftwareDevelopment {...props} />}
        />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
