import React from "react";
import "./footer.scss";
import gps from "./images/gps.svg";
import linkedin from "./images/linkedin.svg";
import mail from "./images/mail.svg";
import skype from "./images/skype.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="container container--inner">
          <div className="block">
            <p>Contacts</p>
            <div className="items">
              <div className="item">
                <img src={skype} alt={skype} />
                <a href="skype:alex.skory?chat">alex.skory</a>
              </div>
              <div className="item">
                <img src={mail} alt={mail} />
                <a href="mailto:oleksandr.skoryi@craftsmenweb.com">
                  oleksandr.skoryi@craftsmenweb.com
                </a>
              </div>
              <div className="item">
                <img src={gps} alt={gps} />
                <a href="https://goo.gl/maps/NTaZTwHwkaZjiaqB8">
                  Ukraine Kyiv, st. Turivska 15
                </a>
              </div>
              {/*<div class="item">*/}
              {/*  <img src="static/images/common/svg/phone.svg" alt="" />*/}
              {/*  <a href="tel:12345678901">+1 234 567 89 01</a>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="block">
            <p>Social Networks</p>
            <div className="items">
              <div className="item">
                <img src={linkedin} alt={linkedin} />
                <a href="https://www.linkedin.com/in/oleksandr-skoryi/">
                  Linkedin
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container--copyright">
        <div className="container container--inner">
          Copyright © Craftsmenweb 2019 - 2021
        </div>
      </div>
    </footer>
  );
};

export default Footer;
