import React from "react";
import cn from "classnames";
import { NavLink as RouterNavLink } from "react-router-dom";
import s from "./nav-link.module.scss";

const NavLink = ({
  disabled,
  style,
  className,
  activeClassName,
  children,
  to,
}) => {
  return (
    <RouterNavLink
      to={to}
      style={style}
      disabled={disabled}
      className={cn(s.navLink, className)}
      activeClassName={cn(s.active, activeClassName)}
      children={children}
    />
  );
};

export default NavLink;
