import React from "react";
import cn from "classnames";
import s from "./Container.module.scss";

const Container = ({ className, children, full = true, ...props }) => (
  <div className={cn(s.container, full && s.full, className)} {...props}>
    {children}
  </div>
);

export default Container;
