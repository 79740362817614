import cn from "classnames";
import s from "./input.module.scss";

const Input = ({
  className,
  type = "text",
  name,
  label,
  value,
  required = false,
  error,
  placeholder,
  setFieldTouched,
  setFieldValue,
  ...props
}) => {
  const showError = required && error;
  return (
    <div
      className={cn(
        s.input,
        value && s.withValue,
        showError && s.withError,
        className
      )}
    >
      <input
        value={value}
        onChange={(e) => {
          setTimeout(() => setFieldTouched(name, true), 0);
          setFieldValue(name, e.target.value);
        }}
        type={type}
        placeholder={`${placeholder}${required ? " *" : ""}`}
        onBlur={() => {
          setFieldTouched(name, true);
        }}
        {...props}
      />
      {showError && <div className={s.error}>{error}</div>}
    </div>
  );
};

export default Input;
