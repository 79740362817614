import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import "styles/styles.scss";
import App from "./App";

import "./i18n";

const history = createBrowserHistory();

history.listen((_) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
