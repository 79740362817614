import React from "react";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import s from "./Home.module.scss";
import { WorkWithUs, TechStack } from "common/components";
import { Container } from "common/ui-kit";
import whoWeAre from "./images/who-we-are.jpg";
import service1 from "./images/services-1.jpg";
import service2 from "./images/services-2.jpg";
import service3 from "./images/services-3.jpg";

const Home = ({ className }) => {
  return (
    <main className={cn(s.home, className)}>
      <Container className={s.main}>
        <Container full={false} className={s.mainInner}>
          <h1>
            We are here
            <br />
            to build your
            <br />
            <strong>
              exceptional
              <br />
              software
            </strong>
          </h1>
        </Container>
      </Container>
      <Container className={s.whoWeAre}>
        <Container full={false} className={cn(s.inner, s.whoWeAreInner)}>
          <div className={s.image}>
            <img src={whoWeAre} alt={whoWeAre} />
          </div>
          <div className={s.info}>
            <fieldset>
              <legend>
                <h3>Who we are</h3>
              </legend>
              <h4>We are a team of highly qualified developers</h4>
              <ul>
                <li className={s.text}>
                  <span className={s.caret} />
                  Who provides best software engineering practices on the market
                </li>
                <li className={s.text}>
                  <span className={s.caret} />
                  Who can make you application really fast and reliable
                </li>
                <li className={s.text}>
                  <span className={s.caret} />
                  Who can make your business representation attractive
                </li>
                <li className={s.text}>
                  <span className={s.caret} />
                  Who can make the most cost effective solution
                </li>
                <li className={s.text}>
                  <span className={s.caret} />
                  Who could done you project highly scalable both in hardware
                  and human resources
                </li>
              </ul>
              <h4>And Last but NOT THE LEAST</h4>
              <p className={s.text}>Who really loves what we are doing!</p>
            </fieldset>
          </div>
        </Container>
      </Container>
      <Container className={s.services}>
        <Container full={false} className={cn(s.inner, s.servicesInner)}>
          <h3>Services</h3>
          <div className={s.swiperContainer}>
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              loop={true}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                550: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className={s.slider}
            >
              <SwiperSlide className={s.slide}>
                <p className={s.name}>
                  <span className={s.caret} />
                  Legacy software redesign
                </p>
                <div className={s.image}>
                  <img src={service1} alt={service1} />
                </div>
                <a href="/legacy-software-redesign">
                  <button>Discover</button>
                </a>
              </SwiperSlide>
              <SwiperSlide className={s.slide}>
                <p className={s.name}>
                  <span className={s.caret} />
                  Software development
                </p>
                <div className={s.image}>
                  <img src={service2} alt={service2} />
                </div>
                <a href="/software-development">
                  <button>Discover</button>
                </a>
              </SwiperSlide>
              <SwiperSlide className={s.slide}>
                <p className={s.name}>
                  <span className={s.caret} />
                  Outstaffing
                </p>
                <div className={s.image}>
                  <img src={service3} alt={service3} />
                </div>
                <a href="/outstaffing">
                  <button>Discover</button>
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </Container>
      <Container>
        <Container full={false} className={s.inner}>
          <TechStack />
        </Container>
      </Container>
      <Container>
        <Container full={false} className={s.inner}>
          <WorkWithUs />
        </Container>
      </Container>
    </main>
  );
};

export default Home;
