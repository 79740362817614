import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en, ua } from "translates";

const resources = {
  en: {
    translations: en,
  },
  ua: {
    translations: ua,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources,

    fallbackLng: "en",
    // debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });

export const langs = Object.keys(resources);
export default i18n;
