import { Formik } from "formik";
import { Input, Textarea } from "common/ui-kit";
import s from "./WorkWithUs.module.scss";

const WorkWithUs = () => {

  return (
    <div className={s.workWithUs}>
      <h3>Want to work with us?</h3>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{ fullName: "", email: "", phone: "", message: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "This field is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.fullName) {
            errors.fullName = "This field is required";
          }

          if (!values.phone) {
            errors.phone = "This field is required";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldTouched,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => {
          return (
            <form id="feedback" action="">
              <div className={s.col}>
                <div className={s.field}>
                  <Input
                    className={s.input}
                    value={values.fullName}
                    name="fullName"
                    type="text"
                    placeholder="Full name"
                    error={
                      errors.fullName && touched.fullName && errors.fullName
                    }
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    required
                  />
                </div>
                <div className={s.field}>
                  <Input
                    className={s.input}
                    value={values.email}
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    error={errors.email && touched.email && errors.email}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    required
                  />
                </div>
                <div className={s.field}>
                  <Input
                    className={s.input}
                    value={values.phone}
                    name="phone"
                    type="tel"
                    placeholder="Phone"
                    error={errors.phone && touched.phone && errors.phone}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    required
                  />
                </div>
              </div>
              <div className={s.col}>
                <div className={s.field}>
                  <Textarea
                    className={s.input}
                    // value={values.message}
                    name="message"
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Other"
                    error={touched.message && errors.message}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    required
                  />
                </div>
                <button
                  type="button"
                  className={s.field}
                  disabled={!!Object.keys(errors).length || isSubmitting}
                  // disabled
                >
                  {isSubmitting ? "Submitting" : "Cooperate"}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WorkWithUs;
