import React from "react";
import cn from "classnames";
import s from "./SoftwareDevelopment.module.scss";
import { Container } from "common/ui-kit";
import { TechStack, WorkWithUs } from "common/components";
import idea from "./images/svg/idea.svg";
import launch from "./images/svg/launch.svg";
import maintenance from "./images/svg/maintenance.svg";
import prototype from "./images/svg/prototype.svg";
import testing from "./images/svg/testing.svg";
import ui_ux from "./images/svg/ui-ux.svg";
import webDevelop from "./images/svg/web-develop.svg";
import webMobileDevelop from "./images/svg/web-mobile-develop.svg";

const SoftwareDevelopment = ({ className }) => {
  return (
    <main className={cn(s.softwareDevelopment, className)}>
      <Container className={s.main}>
        <Container full={false} className={s.mainInner}>
          <h1>
            We are here
            <br />
            <strong>
              to share our best
              <br />
              practices
            </strong>
          </h1>
        </Container>
      </Container>
      {/* <Container className={s.distributedFirst}>
        <Container
          full={false}
          className={cn(s.distributedFirstInner, s.inner)}
        >
          <h3>Distributed first</h3>
          <div className={s.slider}>slider</div>
        </Container>
      </Container> */}
      <Container className={s.workflow}>
        <Container full={false} className={cn(s.workflowInner, s.inner)}>
          <h3>Workflow Development Process</h3>
          <div className={s.items}>
            <div className={s.item}>
              <div className={s.image}>
                <img src={idea} alt={idea} />
              </div>
              <div className={s.text}>Idea</div>
              <div className={s.description}>
                Together with our BA consultant and Marketing Strategist, we
                will analyze your concept and estimate the required time for its
                implementation into working software.
              </div>
            </div>

            <div className={s.item}>
              <div className={s.image}>
                <img src={prototype} alt={prototype} />
              </div>
              <div className={s.text}>Prototype</div>
              <div className={s.description}>
                To create a final schematic vision of your application, together
                with the Designer and Project Manager, we will create a working
                prototype of your software.
              </div>
            </div>

            <div className={s.item}>
              <div className={s.image}>
                <img src={ui_ux} alt={ui_ux} />
              </div>
              <div className={s.text}>UI/UX</div>
              <div className={s.description}>
                The next stage goes to the web and mobile software design, where
                the Designer creates stylish and modern web/app design
                implementing the latest design trends
              </div>
            </div>

            <div className={s.item}>
              <div className={s.image}>
                <img src={webMobileDevelop} alt="" />
              </div>
              <div className={s.text}>Web/Mobile Development</div>
              <div className={s.description}>
                At this stage, we move to the frontend and backend web
                development. If your project has apps, then iOS and Android apps
                are also released within this stage.
              </div>
            </div>

            <div className={s.item}>
              <div className={s.image}>
                <img src={webDevelop} alt={webDevelop} />
              </div>
              <div className={s.text}>Web Development</div>
              <div className={s.description}>
                At this stage, we move to the frontend and backend web
                development. If your project has apps, then iOS and Android apps
                are also released within this stage.
              </div>
            </div>

            <div className={s.item}>
              <div className={s.image}>
                <img src={testing} alt={testing} />
              </div>
              <div className={s.text}>Testing</div>
              <div className={s.description}>
                At this stage, your software is almost ready. From our side, we
                apply to test to make sure the product works smoothly and with
                no bugs. After the final fixes, the app is ready for launch.
              </div>
            </div>

            <div className={s.item}>
              <div className={s.image}>
                <img src={launch} alt={launch} />
              </div>
              <div className={s.text}>Launch</div>
              <div className={s.description}>
                Here you are getting a ready working software which is prepared
                for further promotion on different marketing channels.
              </div>
            </div>

            <div className={s.item}>
              <div className={s.image}>
                <img src={maintenance} alt={maintenance} />
              </div>
              <div className={s.text}>Maintenance</div>
              <div className={s.description}>
                Every product requires updates and improvements; that's why we
                always assist in further maintenance.
              </div>
            </div>
          </div>
        </Container>
      </Container>
      <Container>
        <Container full={false} className={s.inner}>
          <TechStack />
        </Container>
      </Container>

      <Container>
        <Container full={false} className={s.inner}>
          <WorkWithUs />
        </Container>
      </Container>
    </main>
  );
};

export default SoftwareDevelopment;
