import React from "react";
import cn from "classnames";
import { Container } from "common/ui-kit";
import s from "./Outstaffing.module.scss";

const Outstaffing = ({ className }) => {
  return (
    <main className={cn(s.outstaffing, className)}>
      <Container className={s.main}>
        <Container full={false} className={s.mainInner}>
          <h1>
            We are here
            <br />
            <strong>
              to extend
              <br />
              your team
            </strong>
          </h1>
        </Container>
      </Container>
      <Container className={s.teamIsRight}>
        <Container full={false} className={cn(s.inner, s.teamIsRightInner)}>
          <div id="slider" className={s.slider}>
            <div className={cn(s.slides, s.active, s.active1)}>
              <div className={cn(s.slide, s.slide1)}>
                <h3>Which team is right for you?</h3>
                <div className={cn(s.row, s.title)}>
                  <div>Rank</div>
                  <div>Speciality</div>
                  <div>Years of experience</div>
                </div>
                <div className={s.selects}>
                  <div className={cn(s.row, s.selects)} data-count="1">
                    <select name="rank" id="">
                      <option value="">Select option</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                    </select>
                    <select name="speciality" id="">
                      <option value="">Select option</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                    </select>
                    <select name="yearsOfExperience" id="">
                      <option value="">Select option</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                    </select>
                  </div>
                </div>
                <div className={cn(s.row, s.add)}>
                  <button type="button">+</button>
                </div>
              </div>
              <div className={cn(s.slide, s.slide2)}>
                <h3>Which office is right for you?</h3>
                <div className={s.locations}>
                  <div className={s.locationsAddress}>
                    <div className={s.header}>
                      <div data-number="1" className={cn(s.item, s.active)}>
                        Location 1 name
                      </div>
                      <div data-number="2" className={s.item}>
                        Location 2 name
                      </div>
                      <div data-number="3" className={s.item}>
                        Location 3 name
                      </div>
                    </div>
                    <div className={s.content}>
                      <div className={cn(s.image, s.active)}>
                        <img src="/static/images/home/who-we-are.jpg" alt="" />
                        <div className={s.block} />
                        <div className={s.address}>
                          Adress City
                          <br />
                          Street naaaame 76
                        </div>
                      </div>
                      <div className={s.image}>
                        <img src="/static/images/home/who-we-are.jpg" alt="" />
                        <div className={s.block} />
                        <div className={s.address}>
                          Adress City
                          <br />
                          Street naaaame 77
                        </div>
                      </div>
                      <div className={s.image}>
                        <img src="/static/images/home/who-we-are.jpg" alt="" />
                        <div className={s.block} />
                        <div className={s.address}>
                          Adress City
                          <br />
                          Street naaaame 78
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={s.or}>or</div>
                  <div className={s.locationRemote}>
                    <div className={s.header}>
                      <div data-number="4" className={s.item}>
                        Location 4 name
                      </div>
                    </div>
                    <div className={s.content}>
                      <div className={s.image}>
                        <img src="/static/images/home/project-1.jpg" alt="" />
                        <div className={s.block} />
                        <div className={s.address}>
                          Cost
                          <br />
                          effective
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cn(s.slide, s.slide2)}>
                <h3>Contact form</h3>
                <div className={s.contactForm}>
                  <div className={s.col}>
                    <div className={s.form}>
                      <input name="name" type="text" placeholder="Full name" />
                      <input name="email" type="email" placeholder="E-mail" />
                      <input name="phone" type="tel" placeholder="Phone" />
                      <textarea name="message" id="" cols="30" rows="10" />
                      <button type="button">Cooperate</button>
                    </div>
                  </div>
                  <div className={s.col}>
                    <div className={s.image}>
                      <div className={s.background} />
                      <img src="/static/images/home/project-1.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.controls}>
              <div className={s.prev}>
                <img src="/static/images/common/arrow-left.svg" alt="" />
                Back
              </div>
              <div className={s.current}>
                <span>1</span> /3
              </div>
              <div className={s.next}>
                Next
                <img src="/static/images/common/arrow-right.svg" alt="" />
              </div>
            </div>
          </div>
          <div className={s.thanks}>
            <h3>Thank you, for cooperation. We will contact you soon</h3>
            <div className={s.image}>
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcset="/static/images/outstuffing/svg/thanks-mob.svg"
                />
                <source
                  media="(max-width: 1023px)"
                  srcset="/static/images/outstuffing/svg/thanks-tab.svg"
                />
                <img
                  src="/static/images/outstuffing/svg/thanks-desktop.svg"
                  alt="Chris standing up holding his daughter Elva"
                />
              </picture>
            </div>
          </div>
        </Container>
      </Container>
    </main>
  );
};

export default Outstaffing;
